.App {
  text-align: center;
  background-color: #FFFF;
}
.header,.footer{
  background-color: #FECDD3;
}

.question{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.q_content {
  height: 600px;
  width: 350px;
  padding: 1rem;
  background-color: #F8EDEB    ; /*52B788 , F8EDEB */
  border-radius: 20px;
  box-shadow: 7px 5px 23px -9px rgba(0, 0, 0, 0.75);
  margin-bottom: 1rem;
  -webkit-box-shadow: 7px 5px 23px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 5px 23px -9px rgba(0, 0, 0, 0.75);
  
}

.button{
  font-size: 11px !important;
  margin-top: 30px !important;
  margin-left: 10px !important;
  border : 1px solid !important;
  border-radius: 10px !important;
  width: 50% !important;
}
.snsLogo{
  align-self:flex-start
}

.item-enter {
  opacity: 0;
}

.item-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.item-exit {
  opacity: 1;
}

.item-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
